.main {
  margin: 0 auto;
  padding: 95px 0 45px;
}

.main__wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.main__throphy {
  width: 36px;
  height: 36px;
}

.main__title {
  font-family: "Rubik", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 42.66px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #ffffff;

  text-transform: uppercase;
}

.main__thumb {
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 22px;
}

.main__item--wrapper {
  max-width: 390px;
  width: 100%;
  border: 4px solid #e18e2b66;
  border-radius: 20px;
}

.main__item {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-width: 390px;
  width: 100%;
  height: 348px;
  padding-top: 8px;
  padding: 8px 15px 0;
  border-radius: 15px;
}

.main__item--title {
  font-family: "Rubik", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.18px;
  letter-spacing: -0.035em;
  color: #ffffff;
  text-transform: uppercase;
}

.main__prize--list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 6px;
}

.main__prize--item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 160px;
  width: 100%;
  height: 57px;
  border-radius: 8px;
  background-color: #1a1f33cc;
}

.main__prize--white {
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.5);
}

.main__prize--yellow {
  border: 1px solid #fdf351;
  box-shadow: 0px 0px 16px 0px rgba(253, 243, 81, 0.8);
}

.main__prize--top {
  font-family: "Rubik";
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #ffffff;
}

.main__prize--bottom {
  font-family: "Rubik";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #fdf351;
}

.main__prize--textWhite {
  color: #ffffff;
}

.main__prize--textYellow {
  color: #fdf351;
}

.main__button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 337px;
  width: 100%;
  height: 88px;
  border-radius: 12px;
  background: linear-gradient(
    200.14deg,
    #e5c26c 1.54%,
    #d1992c 27.62%,
    #a66719 96.71%
  );

  border: 2px solid #ffffff33;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30.81px;
  letter-spacing: -0.035em;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
}

.main__bottom--text {
  max-width: 302px;
  width: 100%;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.59px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #ffffff;
}

.main__bottom--yellow {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.59px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #ffe600;
}

.main__fade {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 23.1px;

  background: radial-gradient(
    50% 15689.8% at 50% 50%,
    #f12e75 0%,
    rgba(234, 64, 126, 0) 100%
  );
  color: #ffffff;
}

@media screen and (max-width: 350px) {
  .main__prize--top {
    font-size: 21px;
  }

  .main__prize--bottom {
    font-size: 13px;
  }
}

@media screen and (min-width: 1000px) {
  .main__thumb {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
  }
}
