.footer {
  padding: 22px 0;
  width: 100%;
  background: linear-gradient(92.04deg, #a48b63 -63.13%, #1e1c1d 100%);
}

.footer__logo {
  width: 200px;
  margin: 0 auto 22px;
}

.footer__wrapper {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 16px;
  background: #f7a429;

  border-radius: 12px;
}

.footer__text {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.59px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #000000;
}

.footer__year {
  margin-top: 22px;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.59px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #f7a429;
}

.footer__decline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 334px;
  width: 100%;
  height: 58px;
  border: 2px solid #f6eeae80;
  background: #ffffff;

  font-family: "Rubik", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.07px;
  letter-spacing: -0.035em;
  color: #211d1a;
  cursor: pointer;
  text-decoration: underline;
  border-radius: 12px;
  margin-top: 22px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.2);

  opacity: 1;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;

  max-width: 390px;
  width: 100%;
  height: 293px;
  padding: 12px;
  border-radius: 20px;

  background: linear-gradient(92.04deg, #a48b63 -63.13%, #1e1c1d 100%);

  transform: scale(1) translate(-50%, -50%);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal3 {
  position: absolute;
  top: 50%;
  left: 50%;

  max-width: 390px;
  width: 100%;
  height: 212px;
  padding: 16px;
  border-radius: 14px;

  background: linear-gradient(92.04deg, #a48b63 -63.13%, #1e1c1d 100%);

  transform: scale(1) translate(-50%, -50%);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal2 {
  position: absolute;
  bottom: 0%;
  left: 50%;

  max-width: 425px;
  width: 100%;
  height: 311px;
  padding: 16px;
  border-radius: 14px 14px 0 0;

  background: linear-gradient(92.04deg, #a48b63 -63.13%, #1e1c1d 100%);

  transform: scale(1) translate(-50%, 0);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal__image {
  width: 36px;
  height: 36px;
}

.modal__title {
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.44px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #ffffff;
}

.modal__wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.modal__text {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #ffffff;
}

.modal__input {
  margin-top: 12px;
  max-width: 358px;
  width: 100%;
  border-radius: 12px;
  height: 62px;
  padding-left: 19px;
  background: #ecf5ff;

  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #333333;

  line-height: 23.4px;
  letter-spacing: -0.035em;
  text-align: left;

  border: 2px solid #ba7f22;
}

.modal__input::placeholder {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #8f9499;
}

.modal__button {
  margin-top: 12px;
  max-width: 358px;
  width: 100%;
  height: 62px;
  background: linear-gradient(
    89.14deg,
    #e5c26c 1.54%,
    #d1992c 27.62%,
    #a66719 96.71%
  );

  border: 2px solid #f6aeae80;
  border-radius: 12px;
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.07px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #ffffff;
}

.modal__top--wrapper {
  display: flex;
  justify-content: space-between;
}

.modal__top--wrapper2 {
  display: flex;
  align-items: center;
  gap: 8px;
}

.modal__top--text {
  font-family: "Rubik", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.18px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #ffffff;
}

.modal2__text {
  margin-top: 8px;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.33px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #ffffff;
}

.modal2__button {
  max-width: 393px;
  width: 100%;
  height: 74px;
  font-family: "Rubik", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 37.92px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(
    89.14deg,
    #e5c26c 1.54%,
    #d1992c 27.62%,
    #a66719 96.71%
  );

  border-radius: 12px;
  cursor: pointer;
  border: transparent;
}

.modal2__input {
  margin-top: 16px;
  margin-bottom: 12px;
  background: #ecf5ff;
  border: 2px solid #ba7f22;
  max-width: 393px;
  width: 100%;
  height: 62px;
  padding-left: 19px;
  border-radius: 12px;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.7px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #333333;
}

.modal2__input::placeholder {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.7px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #a1a1a1;
}

@media screen and (min-width: 1430px) {
  .footer {
    position: absolute;
    bottom: 0;
  }
}

@media screen and (max-width: 350px) {
  .modal__title {
    font-size: 20px;
  }

  .modal__text {
    font-size: 16px;
  }

  .modal__top--text {
    font-size: 25px;
  }
}
