.header {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  background: linear-gradient(92.04deg, #a48b63 -63.13%, #1e1c1d 100%);

  box-shadow: 0px 4px 4px 0px #0000000d;
}

.header__logo {
  width: 199px;
  margin: 0 auto;
}
